import React, { useState, useEffect } from 'react';
import './AnalyticStyle.css';
import moment from 'moment';
import {
  DieselGenrator,
  SpecificYeildChanges,
  SpecificYeildForDaily,
} from '../../../Api/DataAnalystApi';
import CombinedChart from '../../Charts/CombinedChart';
import FadeLoader from 'react-spinners/FadeLoader';

/*---------------------------Mui Components ----------------------------------*/
import {
  Button,
  Stack,
  Box,
  Paper,
  TextField,
  Autocomplete,
  OutlinedInput,
  Typography,
  Divider,
  CardContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
/*---------------------------Mui Icons --------------------------*/
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
/*------------------------- External compoenents ---------------------------*/
import Apexchart from './ApexexChart';
import BarChart from '../../Charts/BarChart';
import {
  fetchSiteDetails,
  fetchEquipmentIdDetails,
  fetchParameterListDetails,
} from '../../../Api/ExcelUploadAPi';
import {
  DataAnalystSubmit,
  SubmitDataAnalyst,
  equipmentApplyChanges,
  parameterComparisonApplyChanges,
  ExpectedActual,
} from '../../../Api/DataAnalystApi';
import AreaChart from '../../Charts/AreaChart';

const alertStyle = {
  color: 'white', // Text color
  '& .MuiSvgIcon-root': {
    color: 'white', // Icon color
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const optionTime = [
  { label: 'Today', value: 'Today' },
  { label: 'Yesterday ', value: 'Yesterday' },
  { label: 'Last 7 days', value: 'Last 7 days' },
  { label: 'This Month', value: 'This Month' },
  { label: 'Last Month', value: 'Last Month' },
  { label: 'Custom Range', value: 'custom' },
];
const chartType = [
  { label: 'Daily Generation', value: 'daily_generation' },
  { label: 'Energy Performance', value: 'energy_performance' },
  { label: 'Parameter Comparision', value: 'parameter_comparison' },
  { label: 'DG PV Grid Management', value: 'dgpv' },
];
const typoStyle = {
  textTransform: 'capitalize',
  opacity: '0.8',
};
const monitor_head_text = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginTop: '1%',
  marginLeft: '2%',
};
const filterAlign = {
  marginTop: '5.5%',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '25vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const TabStyle = {
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  fontFamily: 'Inter',
  whiteSpace: 'nowrap',
};

const AddAnalystDemo = () => {
  const [site, setSite] = React.useState('');
  const [equipment, setEquipmentName] = React.useState([]);
  const [equipmentParam, setEquipmentParamName] = React.useState([]);
  const [datalog, setDatalogValue] = React.useState([]);
  const [siteIdName, setSiteId] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentParameter, setParameterSite] = useState([]);
  const [chartName, setChartName] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [range, setRange] = useState('');
  const [barchartdata, setBarchartData] = useState([]);
  const [multiChartData, setMultichartData] = useState([]);
  const [tableData, setTableData] = useState();
  const [selectAllEquipments, setSelectAllEquipments] = useState(false);
  const [selectAllEquipmentParam, setSelectAllEquipmentParam] = useState(false);
  const [chartFromDate, setChartFromDate] = useState('');
  const [chartToDate, setChartToDate] = useState('');
  const [value, setValue] = useState(0);
  const [customView, setCustomView] = useState();
  const [timevalue, setTimeValue] = useState('Today');
  const [chartSite, setChartSite] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);
  const [minToDate, setMinToDate] = useState([]);

  const handleChanges = (event, newValue) => {
    setChartName('');
    setValue(newValue);
  };
  useEffect(() => {
    getSiteCall();
  }, []);
  useEffect(() => {
    fetchApplyChange();
  }, [customView]);

  useEffect(() => {
    if (site) {
      handleTabChange(site, timevalue, range, chartSite);
    }
  }, [site]);

  useEffect(() => {
    if (siteIdName.length > 0) {
      setSite(siteIdName[0].siteName);
      let date = new Date();
      let fromDate = moment(date).format('YYYY-MM-DD');
      const dataModel = {
        FromDate: fromDate,
        GraphType: 'Daily Generation',
        SiteId: siteIdName[0].siteId,
        ToDate: fromDate,
        Range: 'daily',
        timeperiod: 'Today',
      };
      setCustomView(dataModel);

      setChartSite(dataModel.GraphType);

      fetchApplyChange();
    }
  }, [siteIdName]);
  /*----------------------------- Paramater Get call ------------------------------*/
  const fetchParams = async (id) => {
    try {
      let data = await fetchParameterListDetails(id);
      setParameterSite(data);
    } catch (e) {
      console.error(e);
    }
  };
  const handleSite = (data, val) => {
    if (val === null) {
      setSite('');
    } else {
      setSite(val);
      setEquipmentName([]);
      fetchEquipmentDetails(val);
      setSelectedOption('');
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.includes('Select All')) {
      if (selectAllEquipments) {
        setSelectAllEquipments(false);
        setEquipmentName([]);
      } else {
        setSelectAllEquipments(true);
        setEquipmentName([...equipmentData]);
      }
    } else {
      const updatedEquipmentName = value.filter(
        (item) => item !== 'Select All'
      );
      setEquipmentName(updatedEquipmentName);
      setSelectAllEquipments(
        updatedEquipmentName.length === equipmentData.length
      );
    }
  };
  const handleChangeEquipment = (event) => {
    const { value } = event.target;
    if (value.includes('Select All')) {
      if (selectAllEquipmentParam) {
        setSelectAllEquipmentParam(false);
        setEquipmentParamName([]);
      } else {
        setSelectAllEquipmentParam(true);
        setEquipmentParamName([...equipmentParameter]);
      }
    } else {
      const updatedEquipmentParam = value.filter(
        (item) => item !== 'Select All'
      );
      setEquipmentParamName(updatedEquipmentParam);
      setSelectAllEquipmentParam(
        updatedEquipmentParam.length === equipmentParameter.length
      );
    }
  };

  /*--------------------------------Equipment Get  ----------------------------*/
  const fetchEquipmentDetails = async (event) => {
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === event;
    });
    try {
      let data = await fetchEquipmentIdDetails(siteIdFilter[0]?.siteId);
      setEquipments(data);
      let equipmentName = data.map((datav) => {
        return datav.customernaming;
      });
      setEquipmentData(equipmentName);
      fetchParams(siteIdFilter[0]?.siteId);
    } catch (e) {
      console.error(e);
    }
  };

  /*-------------------------- Site Get ---------------------*/
  const getSiteCall = async () => {
    try {
      let id = sessionStorage.getItem('id');
      let data = await fetchSiteDetails(id);
      setSiteId([...new Set(data)]);
    } catch (e) {
      console.error(e);
    }
  };

  const handleChart = (data, event) => {
    setChartName(event);
    setSelectedOption(event);
    setDatalogValue([]);
    setValue();

    if (event === 'Daily Generation') {
      setEquipmentName([]);
      setEquipmentParamName([]);
    } else if (event === 'Energy Performance') {
      fetchEquipmentDetails(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
    } else if (event === 'Parameter Comparision') {
      fetchEquipmentDetails(site);
      fetchParams(site);
      setEquipmentName([]);
      setEquipmentParamName([]);
    }
  };
  const handleTimeChange = (data, event) => {
    setTimeValue(event);

    if (event === null) {
      setTimeValue('');
    } else {
      setTimeValue(event.value);
    }
  };

  /*------------------------- Custom Range Date -------------------------------*/
  const handleChangeFromDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format('YYYY-MM-DD');
      setFromDate(formattedDateTime);
    }
  };
  const handleChangeToDate = (data, value) => {
    if (data !== null) {
      const formattedDateTime = data.format('YYYY-MM-DD');
      setToDate(formattedDateTime);
    }
  };
  useEffect(() => {
    setMinToDate(fromDate);
    // console.log(fromDate);
  }, [fromDate]);

  /*--------------------- Time range ---------------------*/
  useEffect(() => {
    let date = new Date();
    let fromDate = new Date();
    let responseDate = '';
    let toDate = '';
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case 'Today':
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(responseDate);
        setToDate(toDate);
        setRange('daily');
        break;
      case 'Yesterday':
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDate(responseDate);
        setToDate(responseDate);
        setRange('daily');
        break;
      case 'Last 7 days':
        fromDate.setDate(date.getDate() - 7);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setFromDate(responseDate);
        setToDate(toDate);
        setRange('custom');
        break;
      case 'This Month':
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formatDate(startDate));
        setToDate(formatDate(endDate));
        setRange('custom');
        break;
      case 'Last Month':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        setFromDate(formatDate(firstDayOfLastMonth));
        setToDate(formatDate(lastDayOfLastMonth));
        setRange('custom');
        break;
      case 'custom':
        setRange('custom');
        break;
      default:
        break;
    }
  }, [timevalue]);

  /*------------------------------------------  Apply changes ----------------------*/

  const handleApplyChanges = async () => {
    setLoading(true);
    setDatalogValue([site, equipment, equipmentParam]);
    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });
    let filteredObjects = equipments?.filter((obj) =>
      equipment.includes(obj.customernaming)
    );
    const equipmentIds = filteredObjects?.map((data) => {
      return data.equipmentid;
    });
    let equipmentParameter = equipmentParam?.map((data) => {
      return data;
    });

    let dataModel = {
      SiteId: siteIdFilter[0]?.siteId,
      EquipmentIds: equipmentIds,
      Parameters: equipmentParameter,
      Range:
        chartName === 'Specific Yield' &&
        (timevalue === 'today' || timevalue === 'yesterday')
          ? 'daily'
          : fromDate === toDate
          ? 'daily'
          : range,
      timeperiod: timevalue,
      FromDate: fromDate,
      ToDate: toDate,
      GraphType: chartName,
    };
    setLoading(true);
    setChartFromDate(fromDate);
    setChartToDate(toDate);

    if (chartName === 'Daily Generation') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      let responseData = await DataAnalystSubmit(dataModel);
      setTableData(responseData);

      let seriesData = [];
      const irradiationSeries = {
        name: 'irradiation',
        data: [],
      };
      const todayEnergySeries = {
        name: 'todayenergy',
        data: [],
      };
      responseData.forEach((dataPoint) => {
        const timestamp = new Date(dataPoint.timestamp).getTime();
        irradiationSeries.data.push([timestamp, dataPoint.irradiation || 0]);
        todayEnergySeries.data.push([timestamp, dataPoint.todayEnergy || 0]);
      });
      seriesData.push(irradiationSeries);
      seriesData.push(todayEnergySeries);
      setMultichartData(seriesData);
      setLoading(false);
      if (range !== 'daily' || range !== 'Yesterday') {
      }
    } else if (chartName === 'Energy Performance') {
      delete dataModel.Parameters;
      try {
        let responseData = await equipmentApplyChanges(dataModel);
        setLoading(false);
        let barChartData = responseData;
        setTableData(responseData);
        if (range === 'daily' || range === 'Yesterday') {
          const equipmentMap = {};
          equipments.forEach((equipment) => {
            equipmentMap[equipment.equipmentid] = equipment.customernaming;
          });
          const seriesData = [];
          equipments.forEach((equipment) => {
            const series = {
              name: equipment.customernaming,
              data: [],
            };
            const equipmentData = responseData.filter(
              (data) => data.equipmentId === equipment.equipmentid
            );
            equipmentData.forEach((data) => {
              const timestamp = new Date(data.timestamp).getTime();
              const value = data.todayEnergy !== null ? data.todayEnergy : 0;
              series.data.push([timestamp, value]);
            });
            seriesData.push(series);
          });
          let finalArray = seriesData?.filter((value) => {
            return value.data.length > 0;
          });
          setMultichartData(finalArray);
        } else {
          const convertedData = barChartData.reduce((result, item) => {
            const found = result.find(
              (x) => x.name === item.equipmentId.toString()
            );
            if (found) {
              found.data.push(item.todayEnergy);
            } else {
              result.push({
                name: item.equipmentId.toString(),
                data: [item.todayEnergy],
              });
            }
            return result;
          }, []);
          let converteddata = convertedData;
          let dataMap = new Map(
            equipments.map((item) => [
              String(item.equipmentid),
              item.customernaming,
            ])
          );
          converteddata.forEach((item) => {
            if (dataMap.has(item.name)) {
              item.name = dataMap.get(item.name);
            }
          });
          setBarchartData(convertedData);
          setTableData(responseData);
        }
      } catch (e) {
        console.error(e);
      }
    } else if (chartName === 'Parameter Comparision') {
      try {
        let responseData = await parameterComparisonApplyChanges(dataModel);
        setLoading(false);
        setTableData(responseData);
        const equipmentMap = {};
        const dataFields = equipmentParameter;
        const seriesData = [];
        dataFields.forEach((field) => {
          responseData.forEach((data) => {
            let equipmentName = equipments?.filter((dataValue) => {
              return dataValue.equipmentid === Number(data.equipmentid);
            });
            const timestamp = new Date(data.timestamp).getTime();
            const value = data[field] !== null ? Number(data[field]) : 0;
            const seriesName = `${equipmentName[0]?.customernaming} - ${field}`;
            const existingSeries = seriesData.find(
              (series) => series.name === seriesName
            );
            if (existingSeries) {
              existingSeries.data.push([timestamp, value]);
            } else {
              const newSeries = {
                name: seriesName,
                data: [[timestamp, value]],
              };
              seriesData.push(newSeries);
              let filteredArray = seriesData.map((obj) => {
                obj.data = obj.data.map(([timestamp, value]) => [
                  timestamp,
                  isNaN(value) ? 0 : value,
                ]);
                return obj;
              });
              setMultichartData(filteredArray);
            }
          });
        });
      } catch (e) {
        console.error(e);
      }
    } else if (chartName === 'DG PV Grid Management') {
      delete dataModel.Parameters;
      delete dataModel.EquipmentIds;
      // dataModel.Range="daily"

      let responseData = await DieselGenrator(dataModel);

      setLoading(false);
      // console.log(responseData, "responseData");
      setTableData(responseData);
      setChartName(dataModel?.GraphType);
    }
  };
  /*------------------------------KEY Metrics Moniter  ----------------*/
  const datePickerStyle = {
    '& .MuiInputBase-input': {
      fontSize: '1.2rem',
      padding: '4px',
      width: '8vw',
    },
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleTabChange = (site, timevalue, range, GraphType) => {
    setChartSite(GraphType);
    setRange(range);
    setTimeValue(timevalue);
    setChartName('');
    setSelectedOption('');
    setEquipmentName([]);
    setEquipmentParamName([]);

    let siteIdFilter = siteIdName?.filter((data) => {
      return data.siteName === site;
    });

    let date = new Date();
    let fromDate = new Date();
    let responseDate,
      toDate,
      ApplyFromDate,
      ApplyToDate,
      rangeValue,
      timeApiValue = '';
    const formatDate = (inputDate) => {
      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0');
      const year = inputDate.getFullYear();
      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };
    switch (timevalue) {
      case 'Today':
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = 'daily';
        timeApiValue = 'Today';
        break;
      case 'Yesterday':
        date.setDate(date.getDate() - 1);
        responseDate = moment(date).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = 'daily';
        timeApiValue = 'Yesterday';
        break;
      case 'Last 7 days':
        fromDate.setDate(fromDate.getDate() - 6);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD  ');
        setRange('custom');
        ApplyFromDate = responseDate;
        ApplyToDate = toDate;
        rangeValue = 'custom';
        timeApiValue = 'Last 7 days';
        break;
      case 'This Month':
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date);
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        ApplyFromDate = formatDate(startDate);
        ApplyToDate = formatDate(endDate);
        rangeValue = 'custom';
        timeApiValue = 'This Month';
        break;
      case 'Last Month':
        const firstDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          1
        );
        const lastDayOfLastMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        );
        fromDate.setDate(date.getDate() - 30);
        responseDate = moment(fromDate).format('YYYY-MM-DD');
        toDate = moment(date).format('YYYY-MM-DD');
        let dataValue = formatDate(firstDayOfLastMonth);
        let dataValueEnd = formatDate(lastDayOfLastMonth);
        ApplyFromDate = dataValue;
        ApplyToDate = dataValueEnd;
        rangeValue = 'custom';
        timeApiValue = 'Last Month';
        break;
      default:
        break;
    }
    let rangeData =
      GraphType === 'Specific Yield'
        ? range
        : GraphType === 'Daily Generation' && rangeValue === 'daily'
        ? 'daily'
        : 'custom';
    const dataModel = {
      FromDate: ApplyFromDate,
      GraphType: GraphType,
      Range: rangeData,
      SiteId: siteIdFilter[0]?.siteId,
      ToDate: ApplyToDate,
      timeperiod: timeApiValue,
    };

    setCustomView(dataModel);
  };

  /*------------------------------------- Handle Key Metrics Monitor ------------------------------ */
  const fetchApplyChange = async () => {
    if (customView !== undefined) {
      setLoading(true);
      setChartFromDate(customView?.FromDate);
      setChartToDate(customView?.ToDate);
      if (
        customView?.GraphType === 'Specific Yield' &&
        (customView?.timeperiod === 'Today' ||
          customView?.timeperiod === 'Yesterday')
      ) {
        customView.range = 'daily';
        let responseData = await SpecificYeildForDaily(customView);
        setLoading(false);
        setTableData(responseData);
        setChartName(customView?.GraphType);
      } else if (customView?.GraphType === 'Specific Yield') {
        let responseData = await SpecificYeildChanges(customView);
        setLoading(false);
        setTableData(responseData);
        setChartName(customView?.GraphType);
      } else if (customView?.GraphType === 'Actual Vs Expected') {
        let responseData = await ExpectedActual(customView);
        // console.log(responseData, "responseData");
        setTableData(responseData);
        setChartName(customView?.GraphType);
        setLoading(false);
      } else {
        let responseData = await DataAnalystSubmit(customView);

        setLoading(false);
        setDatalogValue(responseData);
        setTableData(responseData);
        setChartName(customView?.GraphType);
        if (customView?.Range === 'daily') {
          let seriesData = [];
          const irradiationSeries = {
            name: 'irradiation',
            data: [],
          };
          const todayEnergySeries = {
            name: 'todayenergy',
            data: [],
          };
          responseData.forEach((dataPoint) => {
            const timestamp = new Date(dataPoint.timestamp).getTime();
            irradiationSeries.data.push([
              timestamp,
              dataPoint.irradiation || 0,
            ]);
            todayEnergySeries.data.push([
              timestamp,
              dataPoint.todayEnergy || 0,
            ]);
          });
          seriesData.push(irradiationSeries);
          seriesData.push(todayEnergySeries);
          console.log(seriesData, 'seriesData');
          // console.log(convertDataFormat(seriesData));
          setMultichartData(seriesData);
        }
      }
    }
  };
  // console.log(chartName, "Actual Vs Expected");
  return (
    <div className="">
      <div className="monitoring-graph">
        <div>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: '22vw',
                // height: "82vh",
              },
              // overflowY: "scroll",
            }}
          >
            <Paper elevation={0}>
              <div>
                <div style={{}}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: '1.2rem',
                      fontWeight: '600',
                      marginTop: '5%',
                    }}
                  >
                    Select Site
                  </Typography>
                </div>
                <div
                  style={{
                    marginLeft: '7%',
                    marginRight: '5%',
                    marginTop: '5%',
                    overflowY: 'auto',
                  }}
                >
                  <div>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={site}
                      options={siteIdName.map((option) => option.siteName)}
                      onChange={(data, event) => handleSite(data, event)}
                      sx={{ width: '17vw' }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select Site ..." />
                      )}
                      classes={{ option: 'autocomplete' }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '10%' }}>
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    fontWeight: '600',
                    marginTop: '5%',
                  }}
                >
                  Key Metrics Monitor
                </Typography>
                <div
                  style={{
                    marginLeft: '10%',
                    marginRight: '5%',
                    marginTop: '5%',
                    overflowY: 'auto',
                    display: 'flex',
                  }}
                >
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChanges}
                    aria-label="Vertical tabs example"
                    sx={{
                      borderRight: 0,
                      borderColor: 'divider',
                      float: 'left',
                      textAlign: 'right',
                    }}
                  >
                    <Tab
                      style={TabStyle}
                      label="Daily Generation - (Today)  "
                      {...a11yProps(0)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Today',
                          'daily',
                          'Daily Generation'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Daily Generation - (Yesterday)`}
                      {...a11yProps(1)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Yesterday',
                          'daily',
                          'Daily Generation'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Daily Generation - (Last Month)`}
                      {...a11yProps(2)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Last Month',
                          'custom',
                          'Daily Generation'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Daily Generation - (This Month)`}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'This Month',
                          'custom',
                          'Daily Generation'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Specific Yield - (Today) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Today',
                          'daily',
                          'Specific Yield'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Specific Yield - (Yesterday) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Yesterday',
                          'daily',
                          'Specific Yield'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Specific Yield - (Last 7 days) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Last 7 days',
                          'custom',
                          'Specific Yield'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Specific Yield - (This month) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'This Month',
                          'custom',
                          'Specific Yield'
                        )
                      }
                    />

                    <Tab
                      style={TabStyle}
                      label={`Actual Vs Expected - (This month) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'This Month',
                          'custom',
                          'Actual Vs Expected'
                        )
                      }
                    />
                    <Tab
                      style={TabStyle}
                      label={`Actual Vs Expected - (Last Month) `}
                      {...a11yProps(3)}
                      onClick={() =>
                        handleTabChange(
                          site,
                          'Last Month',
                          'custom',
                          'Actual Vs Expected'
                        )
                      }
                    />
                  </Tabs>
                </div>
              </div>
            </Paper>
          </Box>
        </div>
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                m: 1,
                width: '100%',
                height: '25vh',
              },
            }}
          >
            <Paper elevation={0}>
              <div style={{ width: 'auto' }}>
                <div>
                  <div className="graph">
                    <Paper elevation={0}>
                      <div className="">
                        <div className="monitor-text">
                          <Stack spacing={5} direction="row">
                            <div>
                              <Typography>Chart Type</Typography>
                              <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={chartType.map(
                                  (option) => option.label
                                )}
                                onChange={(data, event) =>
                                  handleChart(data, event)
                                }
                                sx={{ width: '13vw' }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Chart Type ..."
                                  />
                                )}
                                classes={{ option: 'autocomplete' }}
                                disabled={!site}
                                value={selectedOption}
                              />
                            </div>
                            <div>
                              <Typography style={typoStyle}>
                                Equipments
                              </Typography>
                              <FormControl sx={{ width: '250px' }}>
                                <Select
                                  id="demo-multiple-checkbox"
                                  multiple
                                  disabled={chartName === 'Daily Generation'}
                                  size="small"
                                  value={equipment}
                                  onChange={handleChange}
                                  input={<OutlinedInput />}
                                  MenuProps={MenuProps}
                                  renderValue={(selected) =>
                                    selected.join(', ')
                                  }
                                  placeholder="Select Equipments ..."
                                  className="custom-select"
                                >
                                  <MenuItem
                                    value="Select All"
                                    className="custom-option"
                                  >
                                    <Checkbox
                                      checked={
                                        equipment.length ===
                                        equipmentData.length
                                      }
                                    />
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                  {equipmentData.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      className="autocomplete custom-option"
                                    >
                                      <Checkbox
                                        checked={equipment.includes(name)}
                                      />
                                      <ListItemText
                                        primary={name}
                                        className="autocomplete custom-option"
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <div>
                              <Typography style={typoStyle}>
                                Equipment Measurement Parameter
                              </Typography>
                              <FormControl sx={{ width: '13vw' }}>
                                <Select
                                  id="demo-multiple-checkbox"
                                  multiple
                                  size="small"
                                  disabled={
                                    chartName === 'Parameter Comparision'
                                      ? false
                                      : true
                                  }
                                  value={equipmentParam}
                                  onChange={handleChangeEquipment}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) => selected.join(',')}
                                  MenuProps={MenuProps}
                                  style={{ overflow: 'hidden' }}
                                >
                                  <MenuItem value="Select All">
                                    <Checkbox
                                      checked={
                                        equipment.length ===
                                        equipmentData.length
                                      }
                                    />
                                    <ListItemText primary="Select All" />
                                  </MenuItem>
                                  {equipmentParameter.map((name) => (
                                    <MenuItem
                                      key={name}
                                      value={name}
                                      className="autocomplete"
                                    >
                                      <Checkbox
                                        checked={
                                          equipmentParam.indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText
                                        primary={name}
                                        className="autocomplete"
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Stack>
                        </div>
                        <div className="monitor-text">
                          <Stack spacing={5} direction="row">
                            <div>
                              <Typography>Custom Range </Typography>
                              <Autocomplete
                                size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={optionTime}
                                onChange={(data, value) =>
                                  handleTimeChange(data, value)
                                }
                                sx={{ width: '13vw' }}
                                value={timevalue}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select ..."
                                  />
                                )}
                                classes={{ option: 'autocomplete' }}
                              />
                            </div>
                            <div>
                              <Stack direction="row" spacing={1.5}>
                                <div
                                  style={{
                                    opacity: timevalue !== 'custom' ? 0.5 : 1,
                                    pointerEvents:
                                      timevalue !== 'custom' ? 'none' : 'auto',
                                  }}
                                >
                                  <Typography>From</Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      placeholder="From"
                                      size="small"
                                      onChange={(data, value) =>
                                        handleChangeFromDate(data, value)
                                      }
                                      sx={datePickerStyle}
                                    />
                                  </LocalizationProvider>
                                </div>
                                <div
                                  style={{
                                    opacity: timevalue !== 'custom' ? 0.5 : 1,
                                    pointerEvents:
                                      timevalue !== 'custom' ? 'none' : 'auto',
                                  }}
                                >
                                  <Typography>To</Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      placeholder="To"
                                      size="small"
                                      disableFuture
                                      shouldDisableDate={(date) =>
                                        dayjs(date).isBefore(
                                          dayjs(fromDate),
                                          'day'
                                        )
                                      }
                                      onChange={(data, value) =>
                                        handleChangeToDate(data, value)
                                      }
                                      sx={datePickerStyle}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </Stack>
                            </div>

                            <div
                              style={{
                                marginTop: '1%',
                                marginLeft: '1%',
                                display: 'flex',
                                // justifyContent: "flex-start",
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  textTransform: 'capitalize',
                                  marginTop: '4%',
                                  fontSize: '1.0rem',
                                }}
                                onClick={() => {
                                  handleApplyChanges();
                                }}
                                disabled={
                                  (chartName === 'Energy Performance' &&
                                    site !== '' &&
                                    equipment.length > 0) ||
                                  (chartName === 'Parameter Comparision' &&
                                    site !== '' &&
                                    equipment.length > 0 &&
                                    equipmentParam.length > 0) ||
                                  ((chartName === 'Daily Generation' ||
                                    chartName === 'Specific Yield' ||
                                    chartName === 'Actual Vs Expected' ||
                                    chartName === 'DG PV Grid Management') &&
                                    site !== '' &&
                                    selectedOption !== '')
                                    ? false
                                    : true
                                }
                              >
                                Apply changes
                              </Button>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
          <div
            style={{ marginLeft: '1%' }}
            // style={{ width: "100%" }}
          >
            {/* <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "100%",
                  height: "50vh",
                },
              }}
            > */}
            <Paper elevation={0}>
              <div>
                <div>
                  <div>
                    <div style={{ marginTop: '3%', marginLeft: '0%' }}>
                      {loading ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            //  marginTop: "15%",
                          }}
                        >
                          <FadeLoader color="#000440" loading={true} />
                        </div>
                      ) : datalog?.length > 0 ? (
                        <Card
                          sx={{
                            width: '100%',
                            // height: "43vh",
                            // overflowY: "scroll",
                          }}
                        >
                          <CardContent sx={{ width: '100%', height: '5%' }}>
                            {chartName === 'Parameter Comparision' ? (
                              <Apexchart
                                chartName={chartName}
                                tableData={tableData}
                                multiChartData={multiChartData}
                                name={'paramter'}
                                timeValue={timevalue}
                                widgetName={`${chartName}-${site}`}
                              />
                            ) : chartName === 'Specific Yield' ||
                              chartName === 'Actual Vs Expected' ? (
                              <CombinedChart
                                tableData={tableData}
                                customView={customView}
                                WidgetName={`${chartName}`}
                                chartName={`${chartName}`}
                              />
                            ) : chartName === 'DG PV Grid Management' &&
                              chartFromDate === chartToDate ? (
                              <AreaChart
                                tableData={tableData}
                                chartName={`${chartName}`}
                              />
                            ) : chartFromDate === chartToDate ? (
                              <Apexchart
                                chartName={chartName}
                                tableData={tableData}
                                multiChartData={multiChartData}
                                key="1"
                                name="chartData"
                                widgetName={`${chartName}-${site}`}
                              />
                            ) : (
                              <BarChart
                                tableData={tableData}
                                chartName={chartName}
                                barchartdata={barchartdata}
                                widgetName={`${chartName}-${site}`}
                              />
                            )}
                            {/* { ? (
                                
                              ):(chartName === "SPecific Yield" ?
                              
                              
                              : () ? (
                               
                              ) : (
                               
                              )} */}
                          </CardContent>
                        </Card>
                      ) : (
                        <Card
                          sx={{
                            width: '100%',
                            // height: "43vh",
                            // overflowY: "scroll",
                          }}
                        >
                          <CardContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '50vh',
                            }}
                          >
                            <Typography style={{ fontSize: '1.5rem' }}>
                              No data found in the database
                            </Typography>
                          </CardContent>
                        </Card>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
            {/* </Box> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAnalystDemo;
