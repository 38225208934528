import React, { useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router';
import './SiteStyle.css';
//---------------------mui---------------------//
import { Box, Paper, Table, TableBody, TableCell, Button, TableContainer, TableHead, FormControlLabel, TablePagination, TableRow, Modal, TableSortLabel, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import Switch from '@mui/material/Switch';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

function createData(name, calories, fat, carbs, protein) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
    };
}
const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3, 9),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
    createData('Honeycomb', 408, 3.2, 87, 6.5),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Jelly Bean', 375, 0.0, 94, 0.0),
    createData('KitKat', 518, 26.0, 65, 7.0),
    createData('Lollipop', 392, 0.2, 98, 0.0),
    createData('Marshmallow', 318, 0, 81, 2.0),
    createData('Nougat', 360, 19.0, 9, 37.0),
    createData('Oreo', 437, 18.0, 63, 4.0),
];
const headCells = [
    {
        id: 'name',
        label: 'Site Name',
    },
    {
        id: 'name',
        label: 'Site Id',
    },
    {
        id: 'calories',
        label: 'Status',
    },
    {
        id: 'fat',
        label: 'Today Energy (kWh)',
    },
    {
        id: 'carbs',
        label: 'Equipment Type',
    },
    {
        id: 'protein',
        label: 'Status',
    },
    {
        id: 'protein',
        label: 'Today Energy',
    },
    {
        id: 'protein',
        label: 'Total Energy',
    },
    {
        id: 'protein',
        label: 'Last Data Received ',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead stickyHeader>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function Equipment(props) {
    const { tableValue, isModalOpen, toggleModal } = props;
    console.log(tableValue);
    const Navigate = useNavigate()
    const [filteredTableValue, setfilteredTableValue] = useState(tableValue);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [siteEnergy, setSiteEnergy] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [orderBy, setOrderBy] = React.useState('');
    const [dense, setDense] = React.useState(false);
    const [sortedData, setSortedData] = useState();
    const [sortColumn, setSortColumn] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [ascending, setAscending] = useState(true);
    const handlebackicon = () => {
        Navigate('/menu/SiteOverView')
    }
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };
    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(0);
    // };
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
    }));
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const defaultLoadingStates = {
        customerNaming: true,
        SerialNo: true,
        equipmentType: true,
        status: true,
        todayEnergy: true,
        totalEnergy: true,
        specificYield: true,
        activePower: true,
        lastDataReceived: true,
    };
    const [equipmentNameLoading, setEquipmentNameLoading] = useState(defaultLoadingStates.customerNaming);
    const [SerailNoLoading, setSerailNoLoading] = useState(defaultLoadingStates.SerialNo);
    const [equipmentTypeLoading, setEquipmentTypeLoading] = useState(defaultLoadingStates.equipmentType);
    const [statusLoading, setStatusLoading] = useState(defaultLoadingStates.status);
    const [todayEnergyLoading, setTodayEnergyLoading] = useState(defaultLoadingStates.todayEnergy);
    const [totalEnergyLoading, setTotalEnergyLoading] = useState(defaultLoadingStates.totalEnergy);
    const [specificYieldLoading, setSpecificYieldLoading] = useState(defaultLoadingStates.specificYield);
    const [activePowerLoading, setActivePowerLoading] = useState(defaultLoadingStates.activePower);
    const [lastDataReceivedLoading, setLastDataReceivedLoading] = useState(defaultLoadingStates.lastDataReceived);
    const handleToggle = () => {
        const filteredValues = tableValue?.filter((row) => {
            return (
                (equipmentNameLoading && row.customerNaming) ||
                (SerailNoLoading && row.SerialNo) ||
                (equipmentTypeLoading && row.equipmentType) ||
                (statusLoading && row.status) ||
                (todayEnergyLoading && row.todayEnergy) ||
                (totalEnergyLoading && row.totalEnergy) ||
                (specificYieldLoading && row.specificYield) ||
                (lastDataReceivedLoading && row.lastDataReceived)
            );
        });
        setfilteredTableValue(filteredValues);
    };
    useEffect(() => {
        resetToDefaultColumns();
        setRowsPerPage(10);
    }, []);
    const resetToDefaultColumns = useCallback(() => {
        setEquipmentNameLoading(defaultLoadingStates.customerNaming);
        setSerailNoLoading(defaultLoadingStates.SerialNo);
        setEquipmentTypeLoading(defaultLoadingStates.equipmentType);
        setStatusLoading(defaultLoadingStates.status);
        setTodayEnergyLoading(defaultLoadingStates.todayEnergy);
        setTotalEnergyLoading(defaultLoadingStates.totalEnergy);
        setSpecificYieldLoading(defaultLoadingStates.specificYield);
        setActivePowerLoading(defaultLoadingStates.activePower)
        setLastDataReceivedLoading(defaultLoadingStates.lastDataReceived);
        handleToggle();
    }, [defaultLoadingStates]);
    const handleClickEvent = (rowid) => {
        console.log(rowid);
        Navigate('/menu/equipment', { state: { id: rowid } })
    }
    /*-------------sorting--------------*/
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        setOrder(newOrder);
        setOrderBy(property);
        sortData(tableValue, newOrder, property);
    };
    const sortData = (data, sortOrder, field) => {
        const sortedData = [...data];
        sortedData.sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else if (sortOrder === 'desc') {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
            return 0;
        });
        setfilteredTableValue(sortedData);
    };
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.alerts);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    function getStatusLabel(networkStatus) {
        switch (networkStatus) {
            case '1':
                return 'Active';
            case '2':
                return 'Warning';
            case '3':
                return 'Down';
            case '0':
                return 'Offline';
            default:
                return 'Unknown';
        }
    }
    function getStatusIcon(status) {
        switch (status) {
            case '1':
                return '#0bdf2a';
            case '0':
                return 'grey'; // Set the color for 'Offline' status
            case '2':
                return 'orange'; // Set the color for 'Warning' status
            case '3':
                return 'red'; // Set the color for 'Down' status
            default:
                return 'black'; // Default color for other values
        }
    }
    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, (filteredTableValue?.length || 0) - page * rowsPerPage);
    return (
        // <div> 
        <div>
            {/* <div> */}
                <div>
                    <div>
                        <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
                            <TableContainer style={{ height: '50vh' }}>
                                <Table
                                    // sx={{ minWidth: 750, }}
                                    aria-labelledby="tableTitle"
                                    stickyHeader aria-label="sticky table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            {equipmentNameLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    className=" mouse-over"
                                                    direction={orderBy === 'customerNaming' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('customerNaming')}
                                                >
                                                    Equipment Name
                                                </TableSortLabel>
                                            </TableCell>}
                                            {SerailNoLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    className=" mouse-over"
                                                    direction={orderBy === 'serialNo' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('serialNo')}
                                                >
                                                    Serial No
                                                </TableSortLabel>
                                            </TableCell>}
                                            {todayEnergyLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    className=" mouse-over"
                                                    direction={orderBy === 'todayEnergy' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('todayEnergy')} 
                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Today Energy</span>
                                                        <span>(kWh)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {totalEnergyLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    direction={orderBy === 'totalEnergy' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('totalEnergy')} 
                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Total Energy</span>
                                                        <span>(MWh)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {specificYieldLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    direction={orderBy === 'performanceRatio' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('performanceRatio')}
                                                >
                                                    Specific Yield
                                                </TableSortLabel>
                                            </TableCell>}
                                            {activePowerLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    direction={orderBy === 'activePower' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('activePower')}
                                                >
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <span>Active Power</span>
                                                        <span>(kW)</span>
                                                    </div>
                                                </TableSortLabel>
                                            </TableCell>}
                                            {lastDataReceivedLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    // active={orderBy === 'lastUpdate' ? order : 'desc'}
                                                    direction={orderBy === 'lastUpdate' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('lastUpdate')} // Use 'status' here
                                                >
                                                    Last Updated
                                                </TableSortLabel>
                                            </TableCell>}
                                            {statusLoading && <TableCell align="center">
                                                <TableSortLabel
                                                    //  active={orderBy === 'networkStatus' ? order : 'desc'}
                                                    direction={orderBy === 'networkStatus' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('networkStatus')} // Use 'status' here
                                                >
                                                    Status
                                                </TableSortLabel>
                                            </TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredTableValue
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    {equipmentNameLoading && <TableCell align="center" > {index.customerNaming}</TableCell>}
                                                    {SerailNoLoading && <TableCell align="center" > {index.serialNo}</TableCell>}
                                                    {todayEnergyLoading && <TableCell align="center">{index.todayEnergy}</TableCell>}
                                                    {totalEnergyLoading && <TableCell align="center">{index.totalEnergy}</TableCell>}
                                                    {specificYieldLoading && <TableCell align="center">{index.performanceRatio}</TableCell>}
                                                    {activePowerLoading && <TableCell align="center">{index.activePower}</TableCell>}
                                                    {lastDataReceivedLoading && <TableCell align="center">{index.lastUpdate}</TableCell>}
                                                    {statusLoading && <TableCell align="center" style={{ cursor: "default", maxWidth: '100px', overflow: 'hidden' }}>
                                                        <span style={{
                                                            backgroundColor: getStatusIcon(index.networkStatus),
                                                            color: 'white',
                                                            padding: '5px 10px',
                                                            borderRadius: '50px',
                                                            display: 'inline-block',
                                                            cursor: 'default',
                                                            whiteSpace: 'nowrap'
                                                        }}>   {getStatusLabel(index.networkStatus)}</span>
                                                    </TableCell>}
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={tableValue?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>
                <div>
                    <Modal
                        open={isModalOpen}
                        onClose={toggleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper
                            elevation={0}
                            className='total-equip'
                            sx={{
                                width: '19%',
                                maxWidth: '250px',
                                height: 'max-content',
                                overflow: 'hidden',
                                backgroundColor: 'aliceblue',
                                padding: '10px',
                                position: 'relative',
                                marginLeft: '77%',
                                marginTop: "20.5%",
                            }}
                        >
                            <div>
                                <Grid container spacing={10}>
                                    <Grid item xs={6} md={14}>
                                        <Typography style={{ color: "rgb(18, 127, 216)", marginLeft: "80%", marginTop: "4%", cursor: "pointer" }} onClick={toggleModal} >
                                            <CloseOutlinedIcon />
                                        </Typography>
                                        {equipmentNameLoading ? (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={equipmentNameLoading}
                                                        onChange={() => setEquipmentNameLoading(!equipmentNameLoading)}
                                                        color="primary"
                                                        disabled
                                                    />
                                                }
                                                label="Equipment Name"
                                            />
                                        ) : (
                                            <FormControlLabel
                                                sx={{
                                                    display: 'block',
                                                }}
                                                control={<Switch color="primary" />}
                                                label="Equipment Name"
                                            />
                                        )}
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={SerailNoLoading}
                                                    onChange={() => setSerailNoLoading(!SerailNoLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Serial No"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={equipmentTypeLoading}
                                                    onChange={() => setEquipmentTypeLoading(!equipmentTypeLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Equipment Type"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={statusLoading}
                                                    onChange={() => setStatusLoading(!statusLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Status"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={todayEnergyLoading}
                                                    onChange={() => setTodayEnergyLoading(!todayEnergyLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Today Energy"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={totalEnergyLoading}
                                                    onChange={() => setTotalEnergyLoading(!totalEnergyLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Total Energy "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={specificYieldLoading}
                                                    onChange={() => setSpecificYieldLoading(!specificYieldLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Specific Yield "
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={activePowerLoading}
                                                    onChange={() => setActivePowerLoading(!activePowerLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label="Active Power"
                                        />
                                        <FormControlLabel
                                            sx={{
                                                display: 'block',
                                            }}
                                            control={
                                                <Switch
                                                    checked={lastDataReceivedLoading}
                                                    onChange={() => setLastDataReceivedLoading(!lastDataReceivedLoading)}
                                                    color="primary"
                                                />
                                            }
                                            label=" Last Data Received"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                    </Modal>
                </div>
            {/* </div> */}
        </div >
    )
}