import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, Button, TextField } from "@mui/material";
import "./Loginpage.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import webdyn from "../../images/dvn.jpeg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import "./Loginpage.css"; // Adjust the path to match your CSS file location
import FadeLoader from "react-spinners/FadeLoader";
import Skeleton from "@mui/material/Skeleton";

/*------------------------------- APi calls -------------------------*/
import { PostLogin } from "../../Api/LoginApi";

const Loginpage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [Email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [Password, setPassword] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [open, setOpen] = React.useState(false);
  const [variantText, setVarient] = React.useState("");
  const [textValue, setTextValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailError(!isValidEmail(newEmail));
  };
  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);
  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  useEffect(() => {
    if (apiResponse && apiResponse.status === 200) {
      // window.location.href = "/webdyn/menu";
      navigate("menu");
      sessionStorage.setItem("token", `${apiResponse.data.userName}`);
      sessionStorage.setItem("id", `${apiResponse.data.userId}`);
      sessionStorage.setItem("jwtToken", `${apiResponse.data.accesstoken}`);
      sessionStorage.setItem("email", `${apiResponse.data.emailID}`);
      window.location.reload();
    }
  }, [apiResponse, navigate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const alertStyle = {
    backgroundColor: "red", // Background color
    color: "white", // Text color
    "& .MuiSvgIcon-root": {
      color: "white", // Icon color
    },
  };

  const handleNavigate = async () => {
    let data = {
      email: Email,
      password: Password,
    };
    try {
      setLoading(true);
      let responsedata = await PostLogin(data); // Assuming PostLogin is defined and works
      setApiResponse(responsedata); // Store the API response
      console.log(responsedata, "responsedata");
    } catch (error) {
      if (error.response && error.response.status === 406) {
        setOpen(true);
        setVarient("warning");
        setTextValue("Invalid Password");
      } else if (error.response && error.response.status === 404) {
        setOpen(true);
        setVarient("error");
        setTextValue("Sorry! Your mailId is not with us. Kindly Register!");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    handleEmailChange();
  };
  return (
    <div className="scroll-lock">
      <div className="main-log no-scroll">
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FadeLoader color="#000440" loading={true} />
          </div>
        ) : (
          <>
            <div className="background-image-container"></div>
            <div className="overlay"></div>
            <Box
              sx={{
                margin: "0 auto 0 auto",
                height: "max-content",
                boxSizing: "border-box",
                width: "max-content",
              }}
            >
              <Paper
                elevation={5}
                style={{
                  padding: "10px 30px 10px 30px",
                  boxSizing: "border-box",
                }}
              >
                <div className="main">
                  <div className="eira-logo">
                    <img src={webdyn} style={{ width: "180px" }} />
                  </div>
                  <Typography
                    style={{
                      fontSize: "26px",
                      textAlign: "center",
                      fontWeight: "550",
                      color: "#5b5e5c",
                      margin: "20px 0 0 0",
                      padding: "0",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Welcome to
                  </Typography>
                  <Typography
                    style={{
                      textAlign: "center",
                      margin: "0px 0 0 0",
                      fontSize: "26px",
                      fontWeight: "550",
                      color: "#5b5e5c",
                      padding: "0",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Centralised Web Portal!{" "}
                  </Typography>
                  <Typography
                    style={{
                      margin: "30px 0 0 0",
                      color: "#6c756f",
                      textAlign: "left",
                      padding: "0",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Please sign-in to your account
                  </Typography>
                  <div className="mail-pass">
                    <TextField
                      id="outlined-basic"
                      placeholder="Email"
                      onChange={(e) => handleEmailChange(e)}
                      error={emailError}
                      helperText={
                        emailError ? (
                          <span style={{ color: "#ff0f0f" }}>
                            Please enter a valid email address
                          </span>
                        ) : (
                          ""
                        )
                      }
                      variant="outlined"
                      style={{
                        borderRadius: "9PX",
                        hight: "10px",
                        width: "100%",
                        marginTop: "5px",
                      }}
                    />
                    {/* <FormControl sx={{ m: 3, width: '38ch', borderRadius: "90px", }} variant="outlined"> */}
                    <OutlinedInput
                      sx={{ width: "100%", margin: "20px 0 0 0" }}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      onChange={(m) => setPassword(m.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-placeholder="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      placeholder="Password"
                    />
                    {/* </FormControl> */}
                  </div>
                  <Link
                    component="button"
                    variant="body2"
                    style={{
                      fontSize: "16px",
                      textAlign: "right",
                      width: "100%",
                      margin: "10px 0 0 0",
                      textDecoration: "none",
                      letterSpacing: "0.5px",
                      color: "#000440",
                    }}
                  >
                    {/* <Typography onClick={handleForgot}> Forgot Password? </Typography> */}
                  </Link>
                  {/* <div className='butt'> */}
                  {/* <Stack spacing={2} direction="row"> */}

                  {/* {loading ? (
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "-20%" }}><FadeLoader color="#000440" loading={true} /></div>) :
                                
                                ( */}
                  <Button
                    variant="contained"
                    style={{
                      fontSize: "26px",
                      width: "100%",
                      backgroundColor: "#000440",
                      margin: "20px 0 20px 0",
                      height: "45px",
                      letterSpacing: "0.5px",
                      textTransform: "capitalize",
                    }}
                    onClick={handleNavigate}
                  >
                    Login
                  </Button>
                  {/* )} */}
                  {/* </Stack>
                                </div> */}
                </div>
                <div>
                  <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <Alert
                      onClose={handleClose}
                      severity={variantText}
                      sx={{ width: "100%", ...alertStyle }}
                    >
                      {textValue}
                    </Alert>
                  </Snackbar>
                </div>
              </Paper>
            </Box>
          </>
        )}
      </div>
    </div>
  );
};
export default Loginpage;
