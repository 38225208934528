import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const CombinedChart = (props) => {
  const { tableValue } = props;

  // Extract data for the line chart
  const getYieldChartValue = useMemo(() => {
    let chartYieldValue = {
      performanceRatio: [],
      customerNaming: [],
    };
    tableValue &&
      tableValue.equipmentList.forEach((value) => {
        chartYieldValue = {
          ...chartYieldValue,
          performanceRatio: [...chartYieldValue.performanceRatio, Number(value.performanceRatio)],
          customerNaming: [...chartYieldValue.customerNaming, value.customerNaming],
        };
      });
    return chartYieldValue;
  }, [tableValue]);

  // Extract data for the bar chart
  const getChartValue = useMemo(() => {
    let chartValue = {
      todayValue: [],
      totalValue: [],
      customerNaming: [],
    };
    tableValue &&
      tableValue.equipmentList.forEach((value) => {
        chartValue = {
          ...chartValue,
          todayValue: [...chartValue.todayValue, Number(value.todayEnergy)],
          customerNaming: [...chartValue.customerNaming, value.customerNaming],
        };
      });
    return chartValue;
  }, [tableValue]);

  // Create a combined options object
  const combinedChartOptions = {
    marker:{
      enabled:true
    },
    chart: {
      type: 'line',
    },
    
    title: {
      text: 'Energy Generation & Specific Yield',
      style: {
        fontWeight: 'normal',
      },
    },
    xAxis: {
      categories: getChartValue.customerNaming,
    },

    yAxis: [
      {
        title: {
          text: 'Energy Generation (kWh)',
          style: {
            fontFamily: 'Inter',
          },
        },
      },
      {
        title: {
          text: 'Specific Yield (AC)',
          style: {
            fontFamily: 'Inter',
          },
        },
      
        opposite: true,
        min: 0,
        max: 10,
      },
    ],
    credits: {
      enabled: false,
    },
    // tooltip: {
    //   useHTML: true,
    //   formatter: function () {
    //     return `<div class="custom-tooltip">
    //       <p>${this.point.category}</p>
    //       <p>Today Energy: ${this.y} (kWh)</p>
    //     </div>`;
    //   },
    // },

    tooltip: {
      shared: true
  },
    plotOptions: {
      column: { 
        type: 'column',
        borderRadius: 5, 
      },
      line: { 
        type: 'line',
        marker: {
          enabled: true, 
        },
      },
    },
    series: [
      {
        name: 'Energy Generation (kWh)',
        data: getChartValue.todayValue,
        type: 'column', 
        color: 'LightSkyBlue',
        style: {
          fontFamily: 'Inter',

        },
     
      },
      {
        name: 'Specific Yield (AC)',
        data: getYieldChartValue.performanceRatio,
        yAxis: 1, 
        type: 'line',
        color:'Navy'
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={combinedChartOptions} />
    </div>
  );
};

export default CombinedChart;